import * as React from 'react';

interface PTagInterface {
	text: string;
	bold?: boolean;
}
interface PageIntroSectionProps {
	h2: string;
	pTags: Array<PTagInterface>;
	white?: boolean;
}
const PageIntroSection: React.FC<PageIntroSectionProps> = (
	props: PageIntroSectionProps
) => {
	return (
		<section
			className={[
				'px-3 py-20',
				props.white ? 'bg-white' : 'bg-bt-light-gray'
			].join(' ')}
		>
			<div
				className={
					'container mx-auto flex flex-col items-center justify-center text-center text-bt-gray'
				}
			>
				<h2 className={'pb-8 pt-0 font-rubik text-4xl'}>{props.h2}</h2>
				{props.pTags.map((tag) => {
					return (
						<p
							className={[
								'pb-8 font-open-sans text-2xl last:pb-0',
								tag.bold ? 'font-bold' : ''
							].join(' ')}
						>
							{tag.text}
						</p>
					);
				})}
			</div>
		</section>
	);
};

export default PageIntroSection;
