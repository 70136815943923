import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore, { Autoplay } from 'swiper';

// @ts-ignore
import slide1 from '../../images/story-slide-1.png';
// @ts-ignore
import slide2 from '../../images/story-slide-2.png';
// @ts-ignore
import slide3 from '../../images/story-slide-3.png';
// @ts-ignore
import slide4 from '../../images/story-slide-4.png';
// @ts-ignore
import slide5 from '../../images/story-slide-5.png';
// @ts-ignore
import slide6 from '../../images/story-slide-6.png';
// @ts-ignore
import slide7 from '../../images/story-slide-7.png';
// @ts-ignore
import slide8 from '../../images/story-slide-8.png';

const StorySlides: React.FC = () => {
	SwiperCore.use([Autoplay]);
	return (
		<section>
			<Swiper
				spaceBetween={50}
				loop={true}
				autoplay={{ delay: 3000 }}
				slidesPerView={1}
			>
				<SwiperSlide>
					<img
						className={'w-full'}
						src={slide1}
						alt={'Our story slide 1'}
					/>
				</SwiperSlide>
				<SwiperSlide>
					<img
						className={'w-full'}
						src={slide2}
						alt={'Our story slide 2'}
					/>
				</SwiperSlide>
				<SwiperSlide>
					<img
						className={'w-full'}
						src={slide3}
						alt={'Our story slide 3'}
					/>
				</SwiperSlide>
				<SwiperSlide>
					<img
						className={'w-full'}
						src={slide4}
						alt={'Our story slide 4'}
					/>
				</SwiperSlide>
				<SwiperSlide>
					<img
						className={'w-full'}
						src={slide5}
						alt={'Our story slide 5'}
					/>
				</SwiperSlide>
				<SwiperSlide>
					<img
						className={'w-full'}
						src={slide6}
						alt={'Our story slide 6'}
					/>
				</SwiperSlide>
				<SwiperSlide>
					<img
						className={'w-full'}
						src={slide7}
						alt={'Our story slide 7'}
					/>
				</SwiperSlide>
				{/*<SwiperSlide>*/}
				{/*	<img*/}
				{/*		className={'w-full'}*/}
				{/*		src={slide8}*/}
				{/*		alt={'Our story slide 8'}*/}
				{/*	/>*/}
				{/*</SwiperSlide>*/}
			</Swiper>
		</section>
	);
};

export default StorySlides;
