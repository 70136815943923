import * as React from 'react';

const GetStartedSection: React.FC = () => {
	return (
		<section className={'bg-bt-red px-3 py-20'}>
			<div
				className={
					'container mx-auto flex flex-col items-center justify-center text-center text-white'
				}
			>
				<h2 className={'py-8 font-rubik text-4xl'}>
					READY TO GET STARTED?
				</h2>
				<p className={'font-open-sans text-2xl'}>
					Belle Tire is growing and wants you to join our successful
					team today!
				</p>
				<a href={'/search/'}>
					<button
						className={
							'rounded border px-7 py-3 font-rubik text-xl text-white hover:border-bt-blue hover:bg-bt-blue'
						}
					>
						APPLY NOW
					</button>
				</a>
			</div>
		</section>
	);
};

export default GetStartedSection;
